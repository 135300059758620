import { useParams } from 'react-router-dom'
import { useLotteryDetail } from '../../hooks/useLotteryDetail'
import { DefaultDetailCard, LotteryDetailCard } from './LotteryCard'
import { useLotteryCallback } from '../../hooks/useLotteryCallback'
import { useCallback, useMemo } from 'react'
import TransactionPendingModal from 'components/Modal/TransactionModals/TransactionPendingModal'
import TransactionSubmittedModal from 'components/Modal/TransactionModals/TransactiontionSubmittedModal'
import useModal from '../../hooks/useModal'
import MessageBox from 'components/Modal/TransactionModals/MessageBox'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { CurrencyAmount } from '../../constants/token'

export default function Detail() {
  const { lotteryId } = useParams<{
    lotteryId: string
  }>()
  const lotteryDetail = useLotteryDetail(lotteryId)
  const { takeInCallback, claimCallback } = useLotteryCallback()
  const { showModal, hideModal } = useModal()
  const addTx = useTransactionAdder()

  const callbackFactory = useCallback(
    (summary: string, callback: (...args: any[]) => Promise<any>) => {
      return async (poolId: string, amount: CurrencyAmount) => {
        showModal(<TransactionPendingModal />)
        if (!poolId) return
        try {
          const r = await callback(poolId, amount)
          hideModal()

          addTx(r, {
            summary
          })
          showModal(<TransactionSubmittedModal />)
        } catch (e) {
          showModal(<MessageBox type="error">{(e as any)?.error?.message || (e as Error).message || e}</MessageBox>)
          console.error(e)
        }
      }
    },
    [addTx, hideModal, showModal]
  )

  const handleClaim = useCallback(
    async (poolId: string) => {
      showModal(<TransactionPendingModal />)
      if (!poolId) return
      try {
        const r = await claimCallback(poolId)
        hideModal()

        addTx(r, {
          summary: `Claim ${lotteryDetail?.priceTicket.toExact()}`
        })
        showModal(<TransactionSubmittedModal />)
      } catch (e) {
        showModal(<MessageBox type="error">{(e as any)?.error?.message || (e as Error).message || e}</MessageBox>)
        console.error(e)
      }
    },
    [addTx, claimCallback, hideModal, lotteryDetail, showModal]
  )

  const handleInvest = useMemo(() => {
    if (!takeInCallback) return () => {}
    return callbackFactory(`Join pool`, takeInCallback)
  }, [callbackFactory, takeInCallback])

  return lotteryDetail ? (
    <LotteryDetailCard onRefund={handleClaim} onTake={handleInvest} lottery={lotteryDetail} />
  ) : (
    <DefaultDetailCard />
  )
}
