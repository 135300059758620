import { useLotteryContract } from './useContract'
import { useCallback, useMemo } from 'react'
import { CurrencyAmount } from '../constants/token'

export function useLotteryCallback() {
  const contract = useLotteryContract()

  const takeInCallback = useCallback(
    async (poolId: string, amount: CurrencyAmount): Promise<any> => {
      if (!contract) {
        throw Error('no contract')
      }
      console.log('amount', amount)
      const estimatedGas = await contract.estimateGas
        .buyTicket(poolId, { value: amount.raw.toString() })
        .catch((error: Error) => {
          console.debug(`Failed to deposit token`, error)
          throw error
        })
      return contract?.buyTicket(poolId, { value: amount.raw.toString(), gasLimit: estimatedGas })
    },
    [contract]
  )

  const claimCallback = useCallback(
    async (poolId: string): Promise<any> => {
      if (!contract) {
        throw Error('no contract')
      }
      const estimatedGas = await contract.estimateGas.refund(poolId).catch((error: Error) => {
        console.debug(`Failed to deposit token`, error)
        throw error
      })
      return contract?.refund(poolId, { gasLimit: estimatedGas })
    },
    [contract]
  )

  const createCallback = useCallback(
    async (price: CurrencyAmount, count: string, deadline: string, title: string): Promise<any> => {
      if (!contract) {
        throw Error('no contract')
      }
      const args = [price.raw.toString(), count, deadline, title]
      const estimatedGas = await contract.estimateGas.createPool(...args).catch((error: Error) => {
        console.debug(`Failed to deposit token`, error)
        throw error
      })
      return contract?.createPool(...args, { gasLimit: estimatedGas })
    },
    [contract]
  )
  return useMemo(
    () => ({
      takeInCallback,
      createCallback,
      claimCallback
    }),
    [takeInCallback, createCallback, claimCallback]
  )
}
