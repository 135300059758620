import { Box, Card, Stack, TextField, Typography } from '@mui/material'
import Input from '../../components/Input'
import { useCallback, useMemo, useState } from 'react'
import NumericalInput from '../../components/Input/InputNumerical'
import Button from '../../components/Button/Button'
import { useLotteryCallback } from '../../hooks/useLotteryCallback'
import useModal from '../../hooks/useModal'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { CurrencyAmount, ETHER } from '../../constants/token'
import TransactionPendingModal from '../../components/Modal/TransactionModals/TransactionPendingModal'
import TransactionSubmittedModal from '../../components/Modal/TransactionModals/TransactiontionSubmittedModal'
import MessageBox from '../../components/Modal/TransactionModals/MessageBox'
import { tryParseAmount } from '../../utils/parseAmount'
import moment from 'moment'
import OutlineButton from '../../components/Button/OutlineButton'
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
export default function Create() {
  const [title, setTitle] = useState('')
  const [amount, setAmount] = useState('')
  const [count, setCount] = useState('')
  const [deadline, setDeadline] = useState('')
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  const priceAmount = tryParseAmount(amount, ETHER)
  const deadlineUTC = moment(deadline).valueOf() / 1000

  const { createCallback } = useLotteryCallback()
  const { showModal, hideModal } = useModal()
  const addTx = useTransactionAdder()

  const callbackFactory = useCallback(
    (summary: string, callback: (...args: any[]) => Promise<any>) => {
      return async (price: CurrencyAmount, count: string, deadline: string, title: string) => {
        showModal(<TransactionPendingModal />)
        try {
          const r = await callback(price, count, deadline, title)
          hideModal()
          setDeadline('')
          setAmount('')
          setCount('')
          addTx(r, {
            summary
          })
          showModal(<TransactionSubmittedModal />)
        } catch (e) {
          showModal(<MessageBox type="error">{(e as any)?.error?.message || (e as Error).message || e}</MessageBox>)
          console.error(e)
        }
      }
    },
    [addTx, hideModal, showModal]
  )

  const handleCreate = useMemo(() => {
    if (!createCallback) return () => {}
    return callbackFactory(`Create ${title} Random`, createCallback)
  }, [callbackFactory, createCallback, title])

  return (
    <Box>
      <Card style={{ width: 680 }}>
        <Stack padding={30} gap={20}>
          <Input outlined label={'Title'} value={title} onChange={e => setTitle(e.target.value)} />
          <Stack direction={'row'} gap={20}>
            <NumericalInput
              outlined
              label={'Amount per wallet'}
              value={amount}
              onChange={e => setAmount(e.target.value)}
            />
            <NumericalInput
              placeholder={'0'}
              outlined
              label={'Number of participants'}
              value={count}
              onChange={e => setCount(e.target.value)}
            />
          </Stack>
          <TextField
            onChange={event => setDeadline(event.target.value)}
            id="datetime-local"
            label="Close at"
            type="datetime-local"
            sx={{ width: 250, marginTop: 50 }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <Typography m={'20px 0'}>
            Fill in the name of the event, the number of participants, the number of tokens needed for each wallet to
            participate and the deadline. The prize pool will be opened automatically when the number of participants is
            sufficient.
          </Typography>
          {!amount || !count || !deadline || !title ? (
            <OutlineButton>
              {!amount ? 'Enter Title' : !count ? 'Enter Amount' : !count ? 'Enter Player Count' : 'Select Date'}
            </OutlineButton>
          ) : (
            <Button
              disabled={!priceAmount || !deadlineUTC}
              onClick={() =>
                account
                  ? priceAmount && deadline && handleCreate(priceAmount, count, deadlineUTC.toString(), title)
                  : toggleWalletModal()
              }
            >
              {account ? 'Create Random' : 'Connect Wallet'}
            </Button>
          )}
        </Stack>
      </Card>
    </Box>
  )
}
