import { styled } from '@mui/material'

export const SwitchTabWrapper = styled('div')({
  background: '#F3F3F3',
  width: 'fit-content',
  padding: 2,
  borderRadius: 10,
  whiteSpace: 'nowrap'
})

export const Tab = styled('button')(({ selected }: { selected: boolean }) => ({
  border: 'none',
  borderRadius: 10,
  height: 32,
  background: selected ? '#161616' : 'transparent',
  fontSize: '16px',
  fontWeight: 700,
  color: selected ? '#FFFFFF' : '#BCBCBC',
  borderBottom: selected ? '3px solid rgba(255,255,255, 1)' : '3px solid transparent',
  transition: '0.3s',
  cursor: 'pointer',
  '&:hover': {
    color: '#BCBCBC'
  }
}))

// function SwitchTab({
//   currentTab,
//   onTabClick
// }: {
//   currentTab: UserInfoTabs
//   onTabClick: (tab: UserInfoTabs) => () => void
// }) {
//   return (
//     <SwitchTabWrapper>
//       {Object.keys(UserInfoTabRoute).map(tab => {
//         const tabName = UserInfoTabRoute[tab as keyof typeof UserInfoTabRoute]
//         return (
//           <Tab key={tab} onClick={onTabClick(tab as UserInfoTabs)} selected={currentTab === tab}>
//             {tabName}
//           </Tab>
//         )
//       })}
//     </SwitchTabWrapper>
//   )
// }
