import { Lottery } from '../../hooks/useLotteryList'
import CardContent from '@mui/material/CardContent'
import { Box, Card, Skeleton, Stack, Typography } from '@mui/material'
import { Timer } from '../../components/Timer/idnex'
import { CurrencyAmount } from '../../constants/token'
import { ExternalLink } from '../../theme/components'
import { getEtherscanLink, shortenAddress } from '../../utils'
import CardActions from '@mui/material/CardActions'
import { useActiveWeb3React } from '../../hooks'
import { useSingleCallResult } from '../../state/multicall/hooks'
import { useLotteryContract } from '../../hooks/useContract'
import { ZERO_ADDRESS } from '../../constants'
import { LotteryDetail } from '../../hooks/useLotteryDetail'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Button/Button'
import { useETHBalances } from '../../state/wallet/hooks'
import { useWalletModalToggle } from '../../state/application/hooks'

export default function LotteryCard({ lottery }: { lottery: Lottery }) {
  const { chainId, account } = useActiveWeb3React()
  const history = useHistory()
  const contract = useLotteryContract()
  const res = useSingleCallResult(contract, 'getPoolInfo', [lottery.poolId, account ?? ZERO_ADDRESS])
  const isEnd = Date.now() / 1000 > lottery.deadline || (res?.result?.winner && res.result.winner !== ZERO_ADDRESS)

  return (
    <Card>
      <CardContent>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {lottery.title}
          </Typography>
          <Typography>
            <Timer timer={lottery.deadline * 1000} />
          </Typography>
        </Stack>
        <Typography variant="h5" component="div">
          {CurrencyAmount.ether(lottery.ticketPrice).toSignificant(2)} Matter
        </Typography>
        <ExternalLink href={getEtherscanLink(chainId ?? 1, lottery.creator, 'address')}>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {lottery.creator}
          </Typography>
        </ExternalLink>
        <Typography>{`${res?.result?.numParticipants}/${res?.result?.numTicket}`}</Typography>
      </CardContent>
      <CardActions>
        <Button style={{ height: 48, width: 150 }} onClick={() => history.push(`/detail/${lottery.poolId}`)}>
          {isEnd ? 'Ended' : 'Ongoing'}
        </Button>
      </CardActions>
    </Card>
  )
}

export interface TicketPay {
  poolId: string
  amount: CurrencyAmount
}

export function LotteryDetailCard({
  lottery,
  onTake,
  onRefund
}: {
  lottery: LotteryDetail
  onTake: (poolId: string, amount: CurrencyAmount) => void
  onRefund: (poolId: string) => void
}) {
  const { chainId, account } = useActiveWeb3React()
  const toggleWallet = useWalletModalToggle()
  const balance: CurrencyAmount | undefined = useETHBalances([account ?? undefined])?.[account ?? '']
  const enoughBalance = balance ? !balance.lessThan(lottery.priceTicket) : false
  const isEnd = Date.now() / 1000 > lottery.deadline
  return (
    <Card style={{ width: 680 }}>
      <Stack gap={30}>
        <CardContent>
          <Stack gap={20}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                {lottery.title}
              </Typography>
              <Typography>
                <Timer timer={lottery.deadline * 1000} />
              </Typography>
            </Stack>
            <Typography fontWeight={700} variant="h3" component="div">
              {lottery.priceTicket.toSignificant(2)} Matter <span style={{ fontSize: 16 }}>/per ticket</span>
            </Typography>
            <ExternalLink href={getEtherscanLink(chainId ?? 1, lottery.creator, 'address')}>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                creator: {lottery.creator}
              </Typography>
            </ExternalLink>
            <Typography>{`${lottery.numParticipants}/${lottery?.numTicket}`}</Typography>
          </Stack>
        </CardContent>
        {lottery.winner && lottery.winner !== ZERO_ADDRESS && (
          <Stack alignItems={'center'}>
            <Typography fontWeight={700} variant="h5">
              {shortenAddress(lottery.winner)}
            </Typography>
            <Typography>winner</Typography>
          </Stack>
        )}
        <Typography m={'20px'}>
          Click on the participate button to participate in the bonus pool, the pool will automatically draw the lottery
          after the number of participants meets the requirements, if the number of participants is still insufficient
          when the time is up, participants can claim their token back.
        </Typography>
        <Box padding={30}>
          <Typography textAlign={'right'}>available balance: {balance ? balance.toSignificant(2) : '--'}</Typography>
          {account ? (
            <Button
              disabled={
                (!isEnd && lottery.isParticipant) ||
                (!isEnd && !lottery.isParticipant && !enoughBalance) ||
                (!isEnd && lottery.winner !== ZERO_ADDRESS) ||
                (isEnd && !lottery.isParticipant) ||
                (isEnd && lottery.winner === ZERO_ADDRESS && lottery.isParticipant && lottery.isRefund)
              }
              onClick={() =>
                isEnd && lottery.winner === ZERO_ADDRESS && lottery.isParticipant
                  ? onRefund(lottery.id)
                  : onTake(lottery.id, lottery.priceTicket)
              }
            >
              {isEnd && lottery.winner === ZERO_ADDRESS && lottery.isParticipant && !lottery.isRefund
                ? 'Claim'
                : isEnd && lottery.winner === ZERO_ADDRESS && lottery.isParticipant && lottery.isRefund
                ? 'Claimed'
                : lottery.isParticipant
                ? 'My Participate'
                : isEnd || lottery.winner !== ZERO_ADDRESS
                ? 'The lucky draw has ended.'
                : !enoughBalance
                ? 'Insufficient balance'
                : 'Participate'}
            </Button>
          ) : (
            <Button onClick={toggleWallet}>Connect Wallet</Button>
          )}
        </Box>
      </Stack>
    </Card>
  )
}

export function DefaultDetailCard() {
  return (
    <Card style={{ width: 680 }}>
      <Stack gap={30}>
        <CardContent>
          <Stack gap={20}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Skeleton width={200} variant="text" sx={{ fontSize: 20 }} />
              <Skeleton width={200} variant="text" />
            </Stack>
            <Skeleton sx={{ fontSize: 48 }} variant="text" component="div" />

            <Skeleton sx={{ mb: 1.5 }} variant="text" color="text.secondary" />

            <Skeleton />
          </Stack>
        </CardContent>
        <Stack alignItems={'center'}>
          <Skeleton sx={{ fontSize: 30 }} width={300} variant="text" />
          <Typography>winner</Typography>
        </Stack>
        <Box padding={30}>
          <Skeleton variant="text" height={60} />
        </Box>
      </Stack>
    </Card>
  )
}
