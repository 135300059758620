import { useEffect, useState } from 'react'
import { Axios } from '../utils/axios'
import { useActiveWeb3React } from './index'

export interface Lottery {
  poolId: string
  creator: string
  ticketPrice: string
  ticketNum: string
  deadline: number
  title: string
  joinTimes: string
  status: string
}

export function useLotteryList(page: number, isAll: boolean) {
  const { account } = useActiveWeb3React()
  const [lotteries, setLotteries] = useState<Lottery[]>()
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.log('account', account)
    setLoading(true)
    Axios.get(`list/${isAll ? 'pool' : 'participant'}?offset=${(page - 1) * 12}&limit=12&account=${account}`).then(
      r => {
        setLoading(false)
        if (r.data.code !== 200) {
          throw Error(r.data.msg)
        }
        if (!r.data.data) {
          return
        }
        setTotal(r.data.total)
        setLotteries(r.data.data)
      }
    )
  }, [account, isAll, page])
  return {
    lotteries,
    total,
    loading
  }
}
