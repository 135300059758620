import { useLotteryContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { ZERO_ADDRESS } from '../constants'
import { useMemo } from 'react'
import { useActiveWeb3React } from './index'
import { CurrencyAmount } from '../constants/token'

export interface LotteryDetail {
  id: string
  title: string
  bonus: string
  creator: string
  deadline: number
  isParticipant: boolean
  numParticipants: string
  numTicket: string
  priceTicket: CurrencyAmount
  winner: string
  isRefund: boolean
}
export function useLotteryDetail(lotteryId: string): LotteryDetail | undefined {
  const { account } = useActiveWeb3React()
  const contract = useLotteryContract()
  const res = useSingleCallResult(contract, 'getPoolInfo', [lotteryId, account ?? ZERO_ADDRESS])
  return useMemo(() => {
    const data = res.result
    return data
      ? {
          id: lotteryId,
          title: data.title,
          bonus: data.bonus,
          creator: data.creator,
          deadline: data.deadline,
          isParticipant: data.isParticipant,
          numParticipants: data.numParticipants,
          numTicket: data.numTicket,
          priceTicket: CurrencyAmount.ether(data.priceTicket.toString()),
          winner: data.winner,
          isRefund: data.isRefund
        }
      : undefined
  }, [lotteryId, res])
}
